<template>
    <v-container class="min-h-full">
        <VTopToolbar title="Comments" :showBack="true"></VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader
                type="list-item-avatar@5"
            ></v-skeleton-loader>
        </div>

        <v-btn
            outlined
            rounded
            block
            color="primary"
            v-show="!loading && hasMore"
            :loading="loadingMore"
            @click.stop="loadNextPage()"
        >
            Previous
        </v-btn>

        <h4 class="grey-text font-weight-light mt-3 mb-5" v-if="!loading && !comments.length">
            There are no comments.
        </h4>

        <v-list
            three-line
            v-show="!loading"
            v-for="(item, index) in comments"
            :key="item.id"
            class="comment-container"
            v-bind:class="{
                sameUserAndTime: sameCommentAttribs(item, comments[index - 1]),
                isDeleted: item.isDeleted,
            }"
        >
            <v-subheader v-if="!sameDayAs(item, comments[index - 1])">{{item.createdDay}}</v-subheader>

            <v-list-item class="comment-item">

                <v-list-item-avatar>
                    <img class="user-image" v-bind:src="item.profileImageSrc" />
                </v-list-item-avatar>

                <v-list-item-content>
                    <div class="caption warning--text text--darken-1" v-if="item.isPinned">
                        <v-icon :size="16" color="warning">mdi-pin</v-icon>
                        Pinned
                    </div>

                    <div class="caption grey--text text--darken-2" v-if="item.isDeleted">
                        <v-icon :size="16" color="grey darken-1">mdi-trash-can-outline</v-icon>
                        Deleted
                    </div>

                    <v-list-item-title
                         class="comment-time font-weight-bold"
                    >
                        {{item.userName}}
                        <span v-if="item.isPrivate"><v-icon size="16" class="ml-2">mdi-lock-outline</v-icon></span>
                    </v-list-item-title>
                    <div class="comment-content" v-html="item.comment"></div>
                    <span class="caption font-weight-light text--disabled">{{item.created.format('HH:mm')}}</span>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-text-field
            v-if="!startedComment && !loading"
            block
            outlined
            placeholder="New comment..."
            class="mt-3"
            @focus="startNewComment()" /> 

        <RichTextEditor
            v-if="startedComment"
            v-show="!loading"
            v-model="newComment"
            placeholder="New comment..."
            @init="onRichTextInit"
            class="mt-3"
        />

        <v-col v-if="startedComment" class="text-right pb-1">
            <v-btn color="primary" class="mr-n3" :disabled="isNewCommentEmpty" @click="postComment">
                Send
            </v-btn>
        </v-col>
    </v-container>
</template>

  
<script>
import commentsAgent from "./commentsAgent.js";
import profileAgent from "../Profile/profileAgent.js";
import RichTextEditor from "../../components/RichTextEditor.vue";

const rpp = 10;

const combineCommentsThresholdMins = 15;

// TODO move component to core?
export default {
    components: {
        RichTextEditor,
    },
    data() {
        return {
            sharedoId: null,
            loading: true,
            loadingMore: false,
            comments: [],
            lastPageLoaded: 0,
            hasMore: true,
            newComment: "",
            startedComment: false,
        };
    },
    computed: {
        isNewCommentEmpty () {
            return !this.newComment || this.newComment === "<p></p>";
        },
    },
    mounted() {
        this.sharedoId = this.$route.params.sharedoId;

        this.refresh();
    },
    methods: {
        loadPage() {
            return new Promise((resolve, reject) => {
                this.lastPageLoaded++;

                commentsAgent.getComments(this.sharedoId, rpp, this.lastPageLoaded).then((data) => {
                    this.loading = false;

                    var models = data.rows.map(t => {
                        var created = this.$moment(t.created);

                        return {
                            id: t.id,
                            comment: t.comment,
                            userId: t.userId,
                            userName: t.userFullName,
                            created: created,
                            createdDay: this.toCalendarDate(created),
                            profileImageSrc: profileAgent.defaultProfileUrl,
                            isPrivate: t.isPrivate,
                            isDeleted: t.isDeleted,
                            isPinned: t.isPinned,
                        };
                    });

                    this.enrichProfileImages(models);

                    // API is sorted old to new, so add items to top of list
                    this.comments.unshift.apply(this.comments, models.reverse());

                    var totalPages = Math.ceil(data.totalRows / rpp);
                    var hasMore = totalPages > this.lastPageLoaded;

                    resolve({ hasMore: hasMore });
                })
                .catch(console.error);
            });
        },

        loadNextPage() {
            this.loadingMore = true;
            this.loadPage()
                .then((result) => {
                    this.loadingMore = false;
                    if (!result.hasMore) {
                        this.hasMore = false;
                    }
                })
                .catch(console.error);
        },

        refresh() {
            this.comments = [];
            this.lastPageLoaded = 0;
            this.hasMore = true;
            this.newComment = "";
            this.startedComment = false;
                
            this.loadPage()
                .then(result => {
                    this.hasMore = result.hasMore;
                    
                    this.scrollToBottom();
                })
                .catch(console.error);            
        },

        toCalendarDate(date) {
            return date.calendar(null, {
                lastDay : '[Yesterday]',
                sameDay : '[Today]',
                lastWeek : 'dddd, DD MMMM',
                sameElse : 'L'
            });
        },

        enrichProfileImages(comments) {

            // Get unique user IDs
            var userIds = comments.map(c => c.userId).filter((id, ix, a) => id && a.indexOf(id) === ix);

            userIds.forEach(id =>  {
                profileAgent.getProfileImage(id).then(image => {

                    // Set image src
                    comments
                        .filter(c => c.userId === id)
                        .forEach(c => {
                            c.profileImageSrc = image
                                ? `data:${image.mimeType};base64,${image.contents}`
                                : c.profileImageSrc;
                        });

                }).catch(err => {
                    console.warn(`User ${id} has no profile image (${err})`);
                });
            });
        },

        sameDayAs(comment, previous) {
            return previous && comment.createdDay === previous.createdDay;
        },

        // True if two comments have same: user, privacy and time (with a few mins)
        sameCommentAttribs(comment, previous) {
            if (!previous
                || comment.userId !== previous.userId
                || comment.isPrivate !== previous.isPrivate)
                return false;

            var diff = comment.created.diff(previous.created, "minutes");
            
            return diff < combineCommentsThresholdMins;
        },

        startNewComment() {
            this.startedComment = true;
        },

        postComment() {
            if (this.isNewCommentEmpty) return;

            commentsAgent.postComment(this.sharedoId, this.newComment).then(() => {
                this.refresh();
            })
            .catch(console.error);
        },

        onRichTextInit() {
            setTimeout(() => {
                this.scrollToBottom(() => {
                    document.getElementsByClassName("ProseMirror")[0].focus();
                });
            }, 0);
        },

        scrollToBottom(callback) {
            this.$nextTick(function () {
                window.scrollTo(0, document.body.scrollHeight);
                if (typeof callback === "function") callback();
            });
        },
    },
};
</script>

<style>
.comment-container {
    padding-bottom: 0 !important;
}
.comment-container.isDeleted > *:not(.v-subheader) {
    opacity: .5;
}
.comment-item {
    min-height: 0 !important;
}
.comment-content > p:last-child {
    margin-bottom: 0 !important;
}
.sameUserAndTime .user-image,
.sameUserAndTime .comment-time
{
    display: none;
}
.sameUserAndTime .v-avatar {
    height: 0 !important;
}
.v-text-field__details {
    display: none !important;
}
</style>