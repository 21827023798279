import { SharedoFetch } from '@sharedo/mobile-core'

function getComments(sharedoId, rpp, p)
{
    return SharedoFetch.get(`/api/v1/public/workItem/${sharedoId}/comments?rowsPerPage=${rpp}&startPage=${p || 1}`);
}

function postComment(sharedoId, comment)
{
    return SharedoFetch.post(`/api/v1/public/workItem/${sharedoId}/comments`, { comment: comment });
}

export default 
{
    getComments,
    postComment,
};
